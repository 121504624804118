<!-- 运营物业审核-->
<template>
  <div>
    <component :is="componentName"></component>
  </div>
</template>

<script>
import propertyRepairReviewDetail from "./detail";
import propertyRepairReviewList from "./list";
export default {
  name: "property-repair-review",
  components:{
    propertyRepairReviewList,
    propertyRepairReviewDetail
  },
  watch:{
    '$route.path':function (newval) {
      if(newval.includes('/detail')){
        this.componentName = 'propertyRepairReviewDetail'
        return;
      }
      if(newval.includes('/checkModule/property-service/property-repair-review')){
        this.componentName = 'propertyRepairReviewList'

      }
    }
  },
  data(){
    return {
      componentName:'propertyRepairReviewList'
    }
  }
}
</script>

<style scoped>

</style>